export default {
  0: {
    title: "somethng",
    desc: "a longer description of what you need to do",
    timeline: {
      before: "do before this event",
      after: "do after this even"
    },
    completion: {
      done: false,
      datetime: null
    }
  },
  10: {
    title: "something 2 rellly long sdjkf kjsh dkjahdkfjhs",
    desc: "a longer description of what you need to do",
    timeline: {
      before: "do before this event",
      after: "do after this even"
    },
    completion: {
      done: false,
      datetime: null
    }
  }
};
